import { createContext, useEffect, useState } from 'react';
import { FormContacts } from './components/FormContacts';
import { FormTime } from './components/FormTime';
import { FormPrice } from './components/FormPrice';
import { FormFinal } from './components/FormFinal';
import { Sidebar } from './components/Sidebar';
import { SliderMain } from './components/Slider';
import axios from 'axios';

export const PageContext = createContext();
export const PriceContext = createContext();
export const ValidContext = createContext();

function App() {
    const [currentPage, setCurrentPage] = useState(1);
    const [contextPrice, setContextPrice] = useState('');
    const [contextValid, setContextValid] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const allInfo = {
        image: localStorage.getItem('cert'),
        email: localStorage.getItem('email'),
        to: localStorage.getItem('whom'),
        from: localStorage.getItem('from'),
        // delivert_at: `${localStorage.getItem('newDate')} ${localStorage.getItem('time')}`,
        sum: localStorage.getItem('price'),
        text: localStorage.getItem('text'),
        number: localStorage.getItem('number'),
    };

    const sendRequest = () => {
        axios.post(`https://coopon.wyshshop.ru/api/gift-card/save`, { allInfo }).then((res) => {
            // console.log(res);
            // console.log(res.data);
            window.location.href = res.data.PaymentURL;
        });
    };

    const nextPage = () => {
        if (currentPage >= 1 && currentPage < 4) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const prevPage = () => {
        if (currentPage >= 1 && currentPage <= 4) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    useEffect(() => {
        if (currentPage === 2) {
            if (contextPrice < 1000) {
                setIsDisabled(true);
            } else setIsDisabled(false);
        } else if (currentPage === 3) {
            if (!contextValid) {
                console.log(contextValid);
                setIsDisabled(true);
            } else setIsDisabled(false);
        }
    }, [currentPage, contextPrice, contextValid]);

    return (
        <PageContext.Provider value={[currentPage, setCurrentPage]}>
            <PriceContext.Provider value={[contextPrice, setContextPrice]}>
                <ValidContext.Provider value={[contextValid, setContextValid]}>
                    <div className={`App ${currentPage === 1 ? 'app-full' : ''}`}>
                        <header className="header">
                            <div className="page">0{currentPage}</div>
                            <Sidebar />
                        </header>
                        <div className="main">
                            <SliderMain />
                        </div>
                        <div className={`form-wrapper ${currentPage > 1 ? 'visible' : ''}`}>
                            <FormPrice className={`${currentPage === 2 ? 'visible' : ''}`} />
                            <FormContacts className={`${currentPage === 3 ? 'visible' : ''}`} />
                            {/* <FormTime className={`${currentPage === 4 ? 'visible' : ''}`} /> */}
                            <FormFinal className={`${currentPage === 4 ? 'visible' : ''}`} />
                        </div>

                        <button
                            onClick={currentPage === 4 ? sendRequest : nextPage}
                            type="submit"
                            className={`next-page ${isDisabled ? 'disabled' : ''}`}
                        >
                            {currentPage === 4 ? 'Оплатить' : 'далее'}
                        </button>
                        <button
                            onClick={prevPage}
                            className={`back-page ${currentPage > 1 ? 'visible' : ''} ${
                                currentPage < 2 ? 'disabled' : ''
                            }`}
                        >
                            назад
                        </button>
                    </div>
                </ValidContext.Provider>
            </PriceContext.Provider>
        </PageContext.Provider>
    );
}

export default App;
