import React, { useContext, useEffect, useState } from 'react';
import { PriceContext } from '../App';

export const FormPrice = ({ className }) => {
    const [price, setPrice] = useState('');
    const [contextPrice, setContextPrice] = useContext(PriceContext);

    const UpdatePrice = (e) => {
        setPrice(e.target.value);
        setContextPrice(price);
    };

    useEffect(() => {
        window.localStorage.setItem('price', price);
    }, [price]);

    return (
        <form action="" className={`form ${className}`}>
            <h2 className="form__title">cумма сертификата</h2>
            <div className="input-price-wrapper">
                <label htmlFor="price" className="label">
                    <input
                        className="input"
                        type="text"
                        onChange={UpdatePrice}
                        onKeyUp={UpdatePrice}
                        value={price}
                        placeholder="0"
                        name="price"
                        id="price"
                        maxLength="6"
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                    />
                    <span>₽</span>
                </label>
            </div>
            <small className={`${price < 1000 ? '' : 'hidden'}`}>минимальная сумма 1000₽</small>
            <small className={`${price > 150000 ? '' : 'hidden'}`}>максимальная сумма 150 000₽</small>
        </form>
    );
};
