import React, { useContext, useEffect, useState } from 'react';
import { PageContext } from '../App';

export const FormFinal = ({ className }) => {
    const [currentPage, setCurrentPage] = useContext(PageContext);

    const [email, setEmail] = useState(null);
    const [whom, setWhom] = useState('');
    const [text, setText] = useState('');
    const [from, setFrom] = useState('');
    const [price, setPrice] = useState('');
    const [time, setTime] = useState('');
    const [number, setNumber] = useState('');
    const [newDate, setNewDate] = useState('');
    // const [cityTime, setCityTime] = useState('');

    useEffect(() => {
        setPrice(window.localStorage.getItem('price'));
        setWhom(window.localStorage.getItem('whom'));
        setText(window.localStorage.getItem('text'));
        setFrom(window.localStorage.getItem('from'));
        setEmail(window.localStorage.getItem('email'));
        // setTime(window.localStorage.getItem('time'));
        // setNewDate(window.localStorage.getItem('newDate'));
        setNumber(window.localStorage.getItem('number'));
        // setCityTime(window.localStorage.getItem('cityTime'));
    }, [currentPage]);

    return (
        <div className={`form ${className} final`}>
            {whom || text || from ? (
                <div className="final__header">
                    {whom && <h2 className="final__name">{whom}</h2>}

                    {text && <p className="final__text">{text}</p>}

                    {from && <p className="final__from">{from}</p>}
                </div>
            ) : null}
            <div className="final__block">
                <p className="final__subtitle">e-mail</p>
                <p className="final__number">{email}</p>
            </div>
            <div className="final__block">
                <p className="final__subtitle">телефон</p>
                <p className="final__number">{number}</p>
            </div>
            {/* <div className="final__block">
                <p className="final__subtitle">дата и время доставки</p>
                <p className="final__date">{newDate},</p>
                <div className="d-flex">
                    <p className="final__date">{time}</p>
                    <p className="final__date">{cityTime}</p>
                </div>
            </div> */}
            <div className="final__block">
                <p className="final__price-subtitle">итого</p>
                <p className="final__price">{price} ₽</p>
            </div>
        </div>
    );
};
