import React, { useContext, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { ValidContext } from '../App';

export const FormContacts = ({ className }) => {
    const [contextValid, setContextValid] = useContext(ValidContext);

    const [email, setEmail] = useState('');
    const [whom, setWhom] = useState('');
    const [text, setText] = useState('');
    const [from, setFrom] = useState('');
    const [number, setNumber] = useState('');

    const checkValid = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setContextValid(emailRegex.test(email) && number.replace(/-|\(|\)| |\+|_|/g, '').length === 11);
    };

    useEffect(() => {
        window.localStorage.setItem('whom', whom);
        window.localStorage.setItem('text', text);
        window.localStorage.setItem('from', from);
        window.localStorage.setItem('email', email);
        window.localStorage.setItem('number', number);
        checkValid();
    }, [whom, text, from, email, number]);

    return (
        <form className={`form ${className}`}>
            <h2 className="form__title">Укажите кому карта</h2>
            <label htmlFor="phone" id="phone" className="label">
                <InputMask
                    mask="+7 (999) 999-99-99"
                    className="input"
                    placeholder="+7 (___) __-__-__"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                />
                <input
                    type="email"
                    id="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="e-mail:"
                    className="input"
                />
            </label>
            <label htmlFor="for" id="for" className="label">
                <input
                    type="text"
                    id="for"
                    value={whom}
                    onChange={(e) => setWhom(e.target.value)}
                    placeholder="кому:"
                    className="input"
                />
                {/* <span>кому:</span> */}
            </label>
            <label htmlFor="text" id="text" className="label">
                {/* <input type="text" id="text" placeholder="текст поздравления:" className="input" /> */}
                <textarea
                    name="text"
                    id="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="текст поздравления:"
                    className="input"
                    rows="1"
                    maxLength="400"
                ></textarea>
            </label>
            <label htmlFor="from" id="from" className="label">
                <input
                    type="text"
                    id="from"
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                    placeholder="от кого:"
                    className="input"
                />
                {/* <span>от кого:</span> */}
            </label>
        </form>
    );
};
