import React, { useContext, useEffect } from 'react';
import Slider from 'react-slick';
import cert1 from '../assets/images/rounded-cards/01_cert.png';
import cert2 from '../assets/images/rounded-cards/02_cert.png';
import cert3 from '../assets/images/rounded-cards/03_cert.png';
import cert4 from '../assets/images/rounded-cards/04_cert.png';
import cert5 from '../assets/images/rounded-cards/05_cert.png';
import cert6 from '../assets/images/rounded-cards/06_cert.png';
import cert7 from '../assets/images/rounded-cards/07_cert.png';
import cert8 from '../assets/images/rounded-cards/08_cert.png';
import { PageContext } from '../App';

export const SliderMain = () => {
    const [currentPage, setCurrentPage] = useContext(PageContext);

    useEffect(() => {
        const slider = document.querySelectorAll('.slick-slide');
        slider.forEach(
            (item) =>
                item.classList.contains('slick-current') &&
                window.localStorage.setItem('cert', item.children[0].children[0].children[0].id)
        );
    }, [currentPage]);

    var settings = {
        dots: false,
        centerMode: true,
        infinite: true,
        centerPadding: '365px',
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        swipe: false,
        // swipeToSlide: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1439,
                settings: {
                    centerPadding: '335px',
                },
            },
            {
                breakpoint: 1240,
                settings: {
                    centerPadding: '315px',
                },
            },
            {
                breakpoint: 1170,
                settings: {
                    centerPadding: '305px',
                },
            },
            {
                breakpoint: 1100,
                settings: {
                    centerPadding: '285px',
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    centerMode: false,
                    centerPadding: '0',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className={`slider-wrapper ${currentPage > 1 ? 'mini' : ''}`}>
            <div className="background-circle">
                <div className={`main__title ${currentPage > 1 ? 'hidden' : ''}`}>
                    выбери дизайн
                    <br />
                    карты
                </div>
            </div>
            <div className={`main__title main__title--mobile ${currentPage > 1 ? 'hidden' : ''}`}>
                выбери дизайн
                <br />
                карты
            </div>
            <Slider {...settings} className={`${currentPage > 1 ? 'slide-hidden' : ''}`}>
                <div>
                    <div id="01_cert.png" className="slider-img">
                        <img src={cert1} alt="" />
                    </div>
                </div>
                <div>
                    <div id="02_cert.png" className="slider-img">
                        <img src={cert2} alt="" />
                    </div>
                </div>
                <div>
                    <div id="03_cert.png" className="slider-img">
                        <img src={cert3} alt="" />
                    </div>
                </div>
                <div>
                    <div id="04_cert.png" className="slider-img">
                        <img src={cert4} alt="" />
                    </div>
                </div>
                <div>
                    <div id="05_cert.png" className="slider-img">
                        <img src={cert5} alt="" />
                    </div>
                </div>
                <div>
                    <div id="06_cert.png" className="slider-img">
                        <img src={cert6} alt="" />
                    </div>
                </div>
                <div>
                    <div id="cert7" className="slider-img">
                        <img src={cert7} alt="" />
                    </div>
                </div>
                <div>
                    <div id="cert8" className="slider-img">
                        <img src={cert8} alt="" />
                    </div>
                </div>
            </Slider>
        </div>
    );
};
