import React, { useContext, useEffect, useState } from 'react';
import { PageContext } from '../App';

export const Sidebar = () => {
    const [currentPage, setCurrentPage] = useContext(PageContext);
    const [lineHeight, setLineHeight] = useState(0);

    // const [isActive, setIsActive] = useState(false);

    const changePage = (value) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        switch (currentPage) {
            case 2:
                setLineHeight(6.4);
                break;

            case 3:
                setLineHeight(12.8);
                break;

            case 4:
                setLineHeight(19.2);
                break;

            case 5:
                setLineHeight(25.6);
                break;

            default:
                setLineHeight(0);
                break;
        }
    }, [currentPage]);

    return (
        <div className="sidebar">
            <ul className="sidebar__list">
                <li onClick={() => changePage(1)} className={`${currentPage >= 1 ? 'active' : ''} sidebar__link`}>
                    <span>
                        1. <span className="sidebar__link-text">Дизайн</span>
                    </span>
                </li>
                <li
                    onClick={() => changePage(2)}
                    className={`${currentPage > 1 ? 'active' : ''} ${
                        currentPage === 1 ? 'disabled' : ''
                    } sidebar__link`}
                >
                    <span>
                        2. <span className="sidebar__link-text">Номинал</span>
                    </span>
                </li>
                <li
                    onClick={() => changePage(3)}
                    className={`${currentPage > 2 ? 'active' : ''} ${currentPage < 3 ? 'disabled' : ''} sidebar__link`}
                >
                    <span>
                        3. <span className="sidebar__link-text">Кому</span>
                    </span>
                </li>
                {/* <li
                    onClick={() => changePage(4)}
                    className={`${currentPage > 3 ? 'active' : ''} ${currentPage < 4 ? 'disabled' : ''} sidebar__link`}
                >
                    <span>
                        4. <span className="sidebar__link-text">Время</span>
                    </span>
                </li> */}
                <li
                    onClick={() => changePage(4)}
                    className={`${currentPage > 3 ? 'active' : ''} ${currentPage < 4 ? 'disabled' : ''} sidebar__link`}
                >
                    <span>
                        5. <span className="sidebar__link-text">Оплата</span>
                    </span>
                </li>

                <div className="sidebar__line" style={{ height: `${lineHeight}rem` }}></div>
            </ul>
        </div>
    );
};
